export const faq = [
    {
        question: "WHAT IS AN NFT?",
        answer: "An NFT, or non-fungible token, is a unique, identifiable digital asset stored on the blockchain. An NFT could be a piece of digital artwork, a collectible, or even a digital representation of a real-life physical asset. Ownership of an NFT is easily and uniquely verifiable due to its public listing on the blockchain.",
    },
    {
        question: "WHAT IS MINTING?",
        answer: "Minting refers to the process of tokenizing a digital file, or a digital piece of art, and publishing it on the blockchain. Once an NFT is minted, you can verify ownership and buy, sell, and trade the NFT.",
    },
    {
        question: "HOW MUCH TO MINT A FARIDA HOOKAH NFT?",
        answer: "The Farida Hookah NFT is 0,1 ETH to mint. However, please note that the person claiming the NFT is responsible for paying the gas fee required to process the transaction.",
    },
    {
        question: "HOW DO I SET UP A METAMASK WALLET?",
        answer: <a href='https://metamask.io/faqs/'>Click here for a tutorial</a>,
    },
    {
        question: "HOW DO I GET WHITELISTED?",
        answer: "Whitelisted means you can mint a Farida Hookah NFT on the lowest price of 0.05 on the day of release. Join our discord for more details.",
    },
    {
        question: "HOW DO I MINT MY NFT?",
        answer: "If you are successfully added to the whitelist, the NFTs will be available to mint on the Farida Hookahs NFT website. You will simply be asked to connect your MetaMask wallet to the website, complete the transaction, and the NFT will then be transferred to that wallet.",
    },
    {
        question: "HOW MANY NFT CAN I MINT?",
        answer: "Anyone who is successfully added to the pre-mint whitelist will have the opportunity to mint five NFTs.",
    },
    {
        question: "WHERE CAN I SEE MY NFT?",
        answer: "Once the NFT has been claimed (or “minted”), you will be able to see your NFTon OpenSea, or on any other Ethereum-based NFT marketplaces. You can simply connect your digital wallet to the marketplace, which “signs you in,” and then browse the NFT(s) in your wallet through the marketplace’s interface.",
    },
    {
        question: "HOW CAN I SELL OR TRADE MY NFT IF I WANT TO?",
        answer: "If you would like to sell or trade your Farida Hookah NFT, you can list the token on secondary markets such as OpenSea or other Ethereum-based NFT marketplaces.",
    },
    {
        question: "WHAT ARE SECONDARY ROYALTIES NAD HOW WILL THEY BE USED?",
        answer: "Farida Hookahs will receive 7.9% of all secondary sales. We will use the funds to hire more full-time team members, fund operations, and deliver even more value to our community.",
    },
];