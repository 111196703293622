export const teamMembers = [
    {
        name: "Randa",
        link: "#",
        // img: "/config/images/twitter_logo.png",
        role: "CEO and Creator",
        description: " s an entrepreneur, investor, and proud father. He has been deep in the technology industry for many years now and is excited to be bringing his experience to the blockchain space."
    },
    {
        name: "X",
        link: "#",
        // img: "/config/images/twitter_logo.png",
        role: "Community Manager",
        description: "is an entrepreneur, investor, and proud father. He has been deep in the technology industry for many years now and is excited to be bringing his experience to the blockchain space."
    },
    {
        name: "Heaven",
        link: "#",
        // img: "/config/images/twitter_logo.png",
        role: "Artist",
        description: "is an entrepreneur, investor, and proud father. He has been deep in the technology industry for many years now and is excited to be bringing his experience to the blockchain space."
    },
    {
        name: "Santi",
        link: "#",
        // img: "/config/images/twitter_logo.png",
        role: "Developer",
        description: "is an entrepreneur, investor, and proud father. He has been deep in the technology industry for many years now and is excited to be bringing his experience to the blockchain space."
    },

];