import React, { useState } from 'react';
import styled from 'styled-components';

function FaqElement(props) {

    const [ open, setOpen ] = useState(false);

    const settingOpen = () =>{
        setOpen(!open);
    }

  return (
    <div className='faq_element_wrapper'>
        <div className="faq_subcontainer">
            <h3 className='faq_question'>{props.question}</h3>
            <button onClick={settingOpen}>
            {open ?
        <p className='faq_answer'>⬆</p>
        :
        <p className='faq_answer'>⬇</p>
        }
                
            </button>
        </div>
        {open ?
        <p className='faq_answer'>{props.answer}</p>
        :
        null
        }
    </div>
  )
}

export default FaqElement;