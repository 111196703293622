import React, { useEffect, useState, useRef } from "react";
import * as s from "../styles/globalStyles";
import styled from "styled-components";
import { slide as Menu } from 'react-burger-menu';
import Hamburger from "./Hamburger";
import WhiteDiv from "./WhiteDiv";
import Mint from "./Mint";
import Description from "./Description";
import NftTraits from "./NftTraits";
import News from "./News";
import Team from "./Team";
import Roadmap from "./Roadmap";
import About from "./About";
import Faq from "./Faq";

import Footer from "./Footer";

export const Header = styled.div`
  display: flex;
  justify-content: right;
  width: 100vw;
  height: 90px;
  padding: 0 10px;
  background: var(--primary);
  font-weight: bold;
  color: var(--primary);
  box-shadow: 10px 10px;
`;

export const HeaderItems = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 10px;
    transition: all ease-in-out 300ms;
    z-index: 100;
}
`

export const HeaderSubItems = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all ease-in-out 300ms;
    @media (max-width: 700px) {
      background: var(--primary);
      width: 100%;
      flex-direction: column;
      position: absolute;
      padding-top: 8px;
      align-items: center;
      left: 0;
      font-size: 2.2em;
}
`

export const HamburgerMenu = styled.div`
    width: 30px;
    display: none;
    
    @media (max-width: 700px) {
      display: flex;
      flex-direction: column;
`

export const SpanH = styled.div`
  width: 25px;
  height: 3px;
  margin: 2px auto;
  transition: all 0.3s ease-in-out;
  background: white;
  display: block;
  cursor: pointer;
  position: relative;
  top: 0;
`

export const Banner = styled.div`
    width: 100%;
    height: min-content;
`

export const UlMenu = styled.div`
    display: flex,
    align-items: center,
    justify-content: center,
    padding: 3,
    list-style: none,
`

export const RoadmapContent = styled.div`
    display: flex;
    width: 100%;
    justify-content: space-around;
    align-items: center;

    @media (max-width: 800px) {
      flex-direction: column;
  }
`

export const BgEffect = styled.div`
  position: absolute;
  width: 208px;
  height: 208px;
  left: -28px;
  top: 1264px;
  z-index: 1;
  background: #00B55E;  
  filter: blur(614px);
`

//From Template

export const StyledButton = styled.button`
  padding: 5px 10px;
  border-radius: 7px;
  border: none;
  background: #141E30;  /* fallback for old browsers */
  background: -webkit-linear-gradient(to right, #243B55, #141E30);  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to right, #243B55, #141E30); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

  padding: 10px;
  font-weight: bold;
  color: var(--secondary-text);
  width: 100px;
  cursor: pointer;
  transition: all ease-in-out 200ms;
  :hover{
    transform: scale(1.1);
  }
  :active {
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
  }
`;

export const StyledRoundButton = styled.button`
  padding: 10px;
  border-radius: 100%;
  border: none;
  background-color: var(--primary);
  padding: 10px;
  font-weight: bold;
  font-size: 15px;
  color: var(--primary-text);
  width: 30px;
  height: 30px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 4px 0px -2px rgba(250, 250, 250, 0.3);
  -webkit-box-shadow: 0px 4px 0px -2px rgba(250, 250, 250, 0.3);
  -moz-box-shadow: 0px 4px 0px -2px rgba(250, 250, 250, 0.3);
  :active {
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
  }
`;

export const ResponsiveWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: stretched;
  align-items: stretched;
  width: 100%;
  @media (min-width: 800px) {
    flex-direction: row;
    width: 60%;
  }
`;

export const StyledLogo = styled.img`
  width: 90px;
  height: auto;
`;

export const StyledImgBanner = styled.img`
  width: 100%;
`;

export const StyledImg = styled.img`
  box-shadow: 0px 5px 11px 2px rgba(0, 0, 0, 0.7);
  background-color: var(--accent);
  border-radius: 100%;
  width: 200px;

  @media (min-width: 900px) {
    width: 250px;
  }
  @media (min-width: 1000px) {
    width: 300px;
  }
  transition: width 0.5s;
`;

export const StyledImgSmall = styled.img`
  width: 22px;
`;


export const StyledLink = styled.a`
  color: var(--secondary);
  text-decoration: none;
`;
export const StyledLink2 = styled.a`
  color: white;
  text-decoration: none;
  margin: 0 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: ease-in-out 500ms;
  font-size:0.7em;

  height: 30px;
  padding: 8px 8px;
  :hover{
    background-color: gray;
    
  }
  @media (max-width: 700px) {
    margin-bottom: 15px;
}
`;

const Home = () => {

  const [CONFIG, SET_CONFIG] = useState({
    CONTRACT_ADDRESS: "",
    SCAN_LINK: "",
    NETWORK: {
      NAME: "",
      SYMBOL: "",
      ID: 0,
    },
    NFT_NAME: "",
    SYMBOL: "",
    MAX_SUPPLY: 1,
    WEI_COST: 0,
    DISPLAY_COST: 0,
    GAS_LIMIT: 0,
    MARKETPLACE: "",
    MARKETPLACE_LINK: "",
    SHOW_BACKGROUND: false,
  });

  // Hamb menu
  const [hamburgerOpen, setHamburgerOpen] = useState(false);
  const toggleHamburger = () => {
    setHamburgerOpen(!hamburgerOpen)
  }

  return (
    <div>
      <Header>
        <HeaderItems>

          <HeaderSubItems className="bbb">

            <StyledLink2 target={"_blank"} href="https://faridahookahs.com/" >

              <StyledImgSmall alt={"example"} style={{ margin: 0, marginRight: "4px" }} src={"/config/images/www-logo.png"} />

            </StyledLink2>

            <StyledLink2 target={"_blank"} href="https://www.instagram.com/faridahookahs/" >

              <StyledImgSmall alt={"example"} style={{ margin: 0, marginRight: "4px" }} src={"/config/images/ig_logo.jpeg"} />

            </StyledLink2>

            <StyledLink2 target={"_blank"} href="https://twitter.com/Faridahookahs" >

              <StyledImgSmall alt={"example"} src={"/config/images/twitter_logo.png"} />

            </StyledLink2>

            <StyledLink2 target={"_blank"} href="https://discord.gg/V4KnhxTd" >

              <StyledImgSmall alt={"example"} style={{ margin: 0, marginRight: "4px" }} src={"/config/images/discord_logo.png"} />

            </StyledLink2>

            <StyledLink2 target={"_blank"} href="https://discord.gg/V4KnhxTd" >

              <StyledImgSmall alt={"example"} style={{ margin: 0, marginRight: "4px" }} src={"/config/images/opensea-logo.png"} />

            </StyledLink2>

          </HeaderSubItems>

          <HamburgerMenu>
            <div className="hamburger" onClick={toggleHamburger}>
              <Hamburger isOpen={hamburgerOpen} />
            </div>
            {/* <SpanH></SpanH> <SpanH></SpanH> <SpanH></SpanH> */}
          </HamburgerMenu>

        </HeaderItems>

      </Header>

      <s.Container
        flex={1}
        ai={"center"}
        style={{ padding: 0, background: "var(--primary)" }}
        image={CONFIG.SHOW_BACKGROUND ? "/config/images/bg.png" : null}
      >

        <s.SpacerLarge />

        {/* <h1 style={{ fontSize: 36, marginTop: 30, color: "#FFD194" }}>Farida Hookas NFT</h1> */}

        <s.SpacerLarge />


        {/* Gif IMAGE */}
        <div className="home_first_row">
          <div className="first_row_left">
            <img src="/config/images/hooka1.png" alt="animated gif" />
          </div>
          <div className="first_row_right">
            <h2>FARIDA HOOKAHS NFT</h2>
            <br />
            <h3><span className="span">A COLLECTION OF 999 NFTS -
              YOUR KEY TO AN EXCLUSIVE WORLDWIDE HOOKAH COMMUNITY</span></h3>
            <br />
            <p>Farida hookahs are born of a passion for design.
              We do not consider ourselves simply hookah traders
              or manufacturers, we are hookah connoisseurs,
              investing decades of product-design experience into
              creating previously unimaginable pieces. Farida Hookahs are
              both a dazzling work of art and truly technically superior products with
              unparalleled smoking efficiency.</p>

            <Mint/>

          </div>
        </div>

        <s.SpacerMedium />

        <Description></Description>

        <Roadmap></Roadmap>

        <News></News>

        <Team></Team>

        {/* <NftTraits></NftTraits> */}

        {/* <About></About> */}

        <Faq></Faq>

      </s.Container>


      {/* <Footer></Footer> */}

      <style jsx>{`
        .bbb{
          top: ${hamburgerOpen ? '0px' : '-300px'};
        }
      `}

      </style>
    </div>
  )
}

export default Home