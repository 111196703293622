import React from 'react';

function TeamMember(props){
    return (
        <div className="member_item">
            <img style={{marginBottom:10}} className='member_img' src={props.teamImg} alt="member img" />

            <div className="member_container">
                <h3>{props.name}</h3> <a href={props.link}><img src={props.img} alt="" /></a>
            </div>

            <p className='team_role'> <span className='span'>{props.role}</span></p>
            {/* <p className='team_desc'>{props.description}</p> */}
        </div>
    )
}

export default TeamMember;