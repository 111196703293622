import React from 'react'

const WhiteDiv = () => {
  return (
    <div className='whiteDiv'>
        
    </div>
  )
}

export default WhiteDiv