import React from 'react'

const Description = () => {
    return (
        <div className='description_section'>
            <div className="description_container">
                <h2>WORLD'S FIRST HOOKAH NFT COLLECTION</h2>
                <h3>We design your imagination</h3>
                <p>Each day sees our designers working with the artisans
                    and craftsmen to imagine up stunning new hookahs, challenging
                    ourselves to break the boundaries of what was once thought possible.
                    Melding brass, wood, glass, engraving, copper, calligraphy, ceramics,
                    clay, plastic and leather in previously unthinkable ways using cutting
                    edge techniques
                    (many of which we have spearheaded ourselves).</p>
                    <br />
                    <p>
                    In creating an exclusive NFT collection we are infusing
                    the spirit of Farida onto the
                    Ethereum blockchain with the same level of innovation and
                    creativity that makes us unique.</p>
            </div>
            <div className="description_img">
                <img src="/config/images/hooka1.jpeg" alt="description-img"/>
            </div>


        </div>
    )
}

export default Description