import React from 'react'

const News = () => {
    return (
        <div className='news'>
            <div className="news_col_left">
                <h2>FARIDA HOOKAHS IN THE NEWS</h2>
                <p>From being featured in famous rap videos in Amsterdam to beach-side cafes in Rio De Janeiro and thousands of quality reviews online, Farida has become a brand synonymous with designer hookas worldwide.</p>
                <div className="watch_container"><a className='watch_button' href="https://www.youtube.com/results?search_query=farida+hookah">Watch</a></div>
            </div>
            <div className="news_col_right">
                {/* <a href="https://www.youtube.com/watch?v=K8_RDR3KlSg" target={'blank'}><img src="/config/images/news1.png" alt="" /></a>  */}
                {/* <iframe width="420" height="315" src="https://www.youtube.com/embed?v=K8_RDR3KlSg"> </iframe> */}
                <iframe className='yt_vid' style={{marginBottom:'20px'}} width="420" height="315" src="https://www.youtube.com/embed/K8_RDR3KlSg" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                <iframe className='yt_vid' style={{marginBottom:'20px'}} width="420" height="315" src="https://www.youtube.com/embed/T490XfxKLo4" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                {/* <img className='news_img_bottom' src="/config/images/news2.jpg" alt="" /> */}
            </div>
        </div>
    )
}

export default News