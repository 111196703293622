import React from 'react';

function About() {

    return (
        <div className='about_wrapper'>

            <h2 className='about_title' id='about'> About </h2>

            <p className='about1'>A collection of 500 NFT by Farida Hookahs</p>

            <div className="about_container">
                {/* <div className="about_img">
                    <img src="/config/images/about-img.png" alt="about img" />
                </div> */}
                <div className="about_text">
                    <h1 className='about_title2'> Our Collection </h1>
                    <br></br>
                    <p className='about2'>Farida hookahs are born of a passion for design. At Farida we do not 
                    consider ourselves hookah traders, or simply hookah manufacturers, we are hookah connoisseurs, 
                    investing decades of product-design experience into creating previously unimaginable pieces. 
                    Farida hookahs are both a dazzling work of art and truly technically superior products with their
                     unparalleled smoking efficiency.
                    </p>

                    <p className='about2'>
                    There are hookahs, and then there are Farida hookahs.  
                    But don’t just take our word for it. Take the time to 
                    search through our comprehensive website (each day new products are added) 
                    and search online and on Youtube for the thousands of reviews by Farida fans.
                    </p>
                </div>

                
            </div>

        </div>
    )
}

export default About;