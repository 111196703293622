import React from 'react';

import FaqElement from './FaqElement.jsx';
import { faq } from './faqData.js';

const Faq = () => {

  

  return (
    <div className='faq'>
        <h2>FREQUENTLY ASKED QUESTIONS</h2>

        <div className="faq_container">

          {faq.map((item, key) => {
            return <FaqElement question={item.question} answer={item.answer} />
          })}

        </div>
    </div>
  )
}

export default Faq