import React from 'react'

const Roadmap = () => {
    return (
        <div className='roadmap' id='roadmap'>
            <h2>Smoke Map</h2>

            {/* Visual roadmap */}

            <div className='visual_rm_container'>
                <div className='rm_element1'>
                   <img src="/config/images/hooka3.gif" alt="" />
                </div>

                <div className='rm_element2'>
                    <h3><span className='span'>Holder Benefits</span> </h3>
                    <br></br>
                    <p>• Access to community of hookah lovers and experts around the world</p>
                    <br />
                    <p>• Monthly opportunities to win free Farida Hookahs and Accessories</p>
                    <br />
                    <p>• Exclusive collection drops</p>
                    <br />
                    <p>• Free and discounted shisha benefits in various cafes worldwide</p>
                </div>

                <div className='rm_element3'>
                <h3><span className='span'>Project Details</span> </h3>

                    <br></br>
                    <p>• Mint Date: TBD</p>
                    <br />
                    <p>• Public Sale: 0.08 ETH</p>
                    <br />
                    <p>• Whitelist Sale: 0.05 ETH</p>
                    <br />
                    <p>• Supply: 999</p>
                    {/* <br />
                    <p>• Blockchain: Ethereum</p> */}
                </div>
            </div>

        </div>
    )
}

export default Roadmap;