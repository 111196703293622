import React, { useEffect, useState, useRef } from "react";
import * as s from "./styles/globalStyles";
// import { slide as Menu } from 'react-burger-menu'
import Home from "./components/Home";
import Mint from "./components/Mint";
import About from "./components/About";
import { Link, Route, Routes } from 'react-router-dom';

function App() {
  return (
    <s.Screen>
      <Routes>
        <Route path="/" exact={true} element={<Home/>}/>
        <Route path="/about" exact={true} element={<About/>}/>
        <Route path="/mint" exact={true} element={<Mint/>}/>
      </Routes>
    </s.Screen>
  );
}

export default App;