import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { connect } from "../redux/blockchain/blockchainActions";
import { fetchData } from "../redux/data/dataActions";
import * as s from "../styles/globalStyles";
import styled from "styled-components";

const truncate = (input, len) =>
    input.length > len ? `${input.substring(0, len)}...` : input;

// Styled

export const SpanH = styled.div`
  width: 25px;
  height: 3px;
  margin: 2px auto;
  transition: all 0.3s ease-in-out;
  background: white;
  display: block;
  cursor: pointer;
  position: relative;
  top: 0;
`

//From Template

export const StyledButton = styled.button`
  border-radius: 7px;
  border: none;
  background: var(--gradient-yellow);
  font-family: Old Standard TT;
 
  padding: 10px 20px;
  font-weight: bold;
  font-size: 1.8em;
  color: var(--secondary-text);
  cursor: pointer;
  transition: all ease-in-out 200ms;
  :hover{
    transform: scale(1.1);
  }
  :active {
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
  }
`;

export const StyledRoundButton = styled.button`
  padding: 10px;
  border-radius: 100%;
  border: none;
  background-color: var(--primary);
  padding: 10px;
  font-weight: bold;
  font-size: 15px;
  color: var(--primary-text);
  width: 30px;
  height: 30px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 4px 0px -2px rgba(250, 250, 250, 0.3);
  -webkit-box-shadow: 0px 4px 0px -2px rgba(250, 250, 250, 0.3);
  -moz-box-shadow: 0px 4px 0px -2px rgba(250, 250, 250, 0.3);
  :active {
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
  }
`;

export const ResponsiveWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: stretched;
  align-items: stretched;
  width: 100%;
  @media (min-width: 800px) {
    flex-direction: row;
    width: 60%;
  }
`;

export const StyledLogo = styled.img`
  width: 90px;
  height: auto;
`;

export const StyledImgBanner = styled.img`
  width: 100%;
`;

export const StyledImg = styled.img`
  box-shadow: 0px 5px 11px 2px rgba(0, 0, 0, 0.7);
  background-color: var(--accent);
  border-radius: 100%;
  width: 200px;

  @media (min-width: 900px) {
    width: 250px;
  }
  @media (min-width: 1000px) {
    width: 300px;
  }
  transition: width 0.5s;
`;

export const StyledImgSmall = styled.img`
  width: 22px;
`;


export const StyledLink = styled.a`
  color: var(--secondary);
  text-decoration: none;
`;
export const StyledLink2 = styled.a`
  color: black;
  text-decoration: none;
  margin: 0 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: ease-in-out 500ms;
  :hover{
    background-color: gray;
    
  }
  @media (max-width: 700px) {
    margin-bottom: 15px;
}
`;

const Mint = () => {
    const dispatch = useDispatch();
    const blockchain = useSelector((state) => state.blockchain);
    const data = useSelector((state) => state.data);
    const [claimingNft, setClaimingNft] = useState(false);
    const [feedback, setFeedback] = useState(`Set the amount of NFTs you want to mint`);
    const [mintAmount, setMintAmount] = useState(1);
    const [CONFIG, SET_CONFIG] = useState({
        CONTRACT_ADDRESS: "",
        SCAN_LINK: "",
        NETWORK: {
            NAME: "",
            SYMBOL: "",
            ID: 0,
        },
        NFT_NAME: "",
        SYMBOL: "",
        MAX_SUPPLY: 1,
        WEI_COST: 0,
        DISPLAY_COST: 0,
        GAS_LIMIT: 0,
        MARKETPLACE: "",
        MARKETPLACE_LINK: "",
        SHOW_BACKGROUND: false,
    });

    const claimNFTs = () => {
        let cost = CONFIG.WEI_COST;
        let gasLimit = CONFIG.GAS_LIMIT;
        let totalCostWei = String(cost * mintAmount);
        let totalGasLimit = String(gasLimit * mintAmount);
        let contractOwner = "";

        console.log("Cost: ", totalCostWei);
        console.log("Gas limit: ", totalGasLimit);

        blockchain.smartContract.methods
            .owner()
            .call()
            .then((receipt) => {
                contractOwner = receipt;
                if (contractOwner == blockchain.account) {
                    console.log("Owner is Minting");
                    cost = 0;
                }
                console.log("Contract owner = ", contractOwner, blockchain.account);
            });



        setFeedback(`Minting your ${CONFIG.NFT_NAME}...`);
        setClaimingNft(true);
        blockchain.smartContract.methods
            .mint(mintAmount)
            .send({
                gasLimit: String(totalGasLimit),
                to: CONFIG.CONTRACT_ADDRESS,
                from: blockchain.account,
                value: totalCostWei,
            })
            .once("error", (err) => {
                console.log(err);
                setFeedback("Sorry, something went wrong please try again later. You might have rejected the transaction.");
                setClaimingNft(false);
            })
            .then((receipt) => {
                console.log(receipt);
                setFeedback(
                    `WOW, the ${CONFIG.NFT_NAME} is yours! go visit Opensea.io to view it.`
                );
                setClaimingNft(false);
                dispatch(fetchData(blockchain.account));
            });
    };

    // #####################
    // TEST

    const validateAddress = () => {
        let smartContractAddress = CONFIG.CONTRACT_ADDRESS;
        let validation = false;

        console.log(smartContractAddress);
        console.log(validation);

        blockchain.smartContract.methods
            .walletOfOwner(blockchain.account)
            .call()
            .then((receipt) => {
                console.log(receipt);
                if (receipt.length != 0) {
                    validation = true;
                }
                setFeedback(
                    `Validation went through ===> This user owns the following NFT: ${receipt} `
                );
                dispatch(fetchData(blockchain.account));
            });
    };

    // #####################

    const decrementMintAmount = () => {
        let newMintAmount = mintAmount - 1;
        if (newMintAmount < 1) {
            newMintAmount = 1;
        }
        setMintAmount(newMintAmount);
    };

    const incrementMintAmount = () => {
        let newMintAmount = mintAmount + 1;
        if (newMintAmount > 10) {
            newMintAmount = 10;
        }
        setMintAmount(newMintAmount);
    };

    const getData = () => {
        if (blockchain.account !== "" && blockchain.smartContract !== null) {
            dispatch(fetchData(blockchain.account));
        }
    };

    const getConfig = async () => {
        const configResponse = await fetch("/config/config.json", {
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
            },
        });
        const config = await configResponse.json();
        SET_CONFIG(config);
    };

    useEffect(() => {
        getConfig();
    }, []);

    useEffect(() => {
        getData();
    }, [blockchain.account]);


    return (

        <ResponsiveWrapper flex={1} style={{ width: '100%', display: 'flex', alignItems: 'center',
            borderRadius: "10px" }} test>


            <s.Container
                flex={2}
                jc={"center"}
                ai={"center"}
                style={{
                    padding: 12,
                    borderRadius: 24,
                    border: "4px var(--secondary)",
                    // boxShadow: "0px 5px 11px 2px rgba(0,0,0,0.7)",
                }}
            >


                {/* <s.TextTitle
                    style={{ textAlign: "center", color: "var(--accent-text)" }}>
                    Mint Price: {CONFIG.DISPLAY_COST}{" "}
                    {CONFIG.NETWORK.SYMBOL}
                </s.TextTitle> */}

                {/* <s.TextTitle
                    style={{
                        textAlign: "center",
                        fontSize: 50,
                        fontWeight: "bold",
                        color: "var(--accent-text)",
                    }}
                >
                    {data.totalSupply} / {CONFIG.MAX_SUPPLY}
                </s.TextTitle> */}




                {Number(data.totalSupply) >= CONFIG.MAX_SUPPLY ? (<>
                    <s.TextTitle
                        style={{ textAlign: "center", color: "var(--accent-text)" }}>
                        The sale has ended.
                    </s.TextTitle>
                    <s.TextDescription
                        style={{ textAlign: "center", color: "var(--accent-text)" }}
                    >
                        You can still find {CONFIG.NFT_NAME} on
                    </s.TextDescription>
                    <s.SpacerSmall />
                    <StyledLink target={"_blank"} href={CONFIG.MARKETPLACE_LINK}>{CONFIG.MARKETPLACE}</StyledLink></>
                ) : (<>

                    <s.SpacerSmall />
                    {blockchain.account === "" ||
                        blockchain.smartContract === null ? (
                        <s.Container ai={"center"} jc={"center"}>
                            {/* <s.TextDescription
                                style={{
                                    textAlign: "center",
                                    color: "var(--accent-text)",
                                    width: "60%"
                                }}>
                                NETWORK: {CONFIG.NETWORK.NAME}
                            </s.TextDescription> */}
                            
                            <StyledButton onClick={(e) => { e.preventDefault(); dispatch(connect()); getData(); }}>
                                CONNECT
                            </StyledButton>
                            <s.SpacerSmall />
                            <StyledButton>
                                <a href="https://faridahookahs.com" target={'blank'} style={{textDecoration:'none', color:'white'}}>SHOP</a>
                            </StyledButton>
                            {blockchain.errorMsg !== "" ? (
                                <>
                                    <s.SpacerSmall />
                                    <s.TextDescription
                                        style={{
                                            textAlign: "center",
                                            color: "var(--accent-text)",
                                        }}
                                    >
                                        {blockchain.errorMsg}
                                    </s.TextDescription>
                                </>
                            ) : null}
                        </s.Container>
                    ) : (
                        <>
                            <s.TextDescription
                                style={{
                                    textAlign: "center",
                                    color: "var(--accent-text)",
                                }}
                            >
                                {feedback}
                            </s.TextDescription>
                            <s.SpacerMedium />
                            <s.Container ai={"center"} jc={"center"} fd={"row"}>
                                <StyledRoundButton
                                    style={{ lineHeight: 0.4 }}
                                    disabled={claimingNft ? 1 : 0}
                                    onClick={(e) => {
                                        e.preventDefault();
                                        decrementMintAmount();
                                    }}
                                >
                                    -
                                </StyledRoundButton>
                                <s.SpacerMedium />
                                <s.TextDescription
                                    style={{
                                        textAlign: "center",
                                        color: "var(--accent-text)",
                                    }}
                                >
                                    {mintAmount}
                                </s.TextDescription>
                                <s.SpacerMedium />
                                <StyledRoundButton
                                    disabled={claimingNft ? 1 : 0}
                                    onClick={(e) => {
                                        e.preventDefault();
                                        incrementMintAmount();
                                    }}
                                >
                                    +
                                </StyledRoundButton>
                            </s.Container>
                            <s.SpacerSmall />
                            <s.Container ai={"center"} jc={"center"} fd={"row"}>
                                <StyledButton
                                    disabled={claimingNft ? 1 : 0}
                                    onClick={(e) => {
                                        e.preventDefault();
                                        claimNFTs();
                                        getData();
                                    }}
                                >
                                    {claimingNft ? "BUSY" : "MINT"}
                                </StyledButton>
                            </s.Container>

                        </>
                    )}
                </>
                )}
                <s.SpacerMedium />

                {/* <s.TextDescription
                    style={{
                        textAlign: "center",
                        color: "var(--primary-text)",
                    }}
                >
                    <StyledLink target={"_blank"} href={CONFIG.SCAN_LINK}>
                        {truncate(CONFIG.CONTRACT_ADDRESS, 15)}
                    </StyledLink>
                </s.TextDescription> */}

            </s.Container>
            <s.SpacerLarge />

        </ResponsiveWrapper>


    )
}

export default Mint